
import Card from 'primevue/card'
import Divider from 'primevue/divider'
import { truncate } from '@/utils/math'
export default {
  props: {
    value: { required: true, type: Number },
    variation: { required: true, type: Number },
    lastValue: { required: true, type: Number }
  },
  components: {
    Card,
    Divider
  },
  setup (): Record<string, (value: number, digit?: number) => number> {
    return { truncate }
  }
}

