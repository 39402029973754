import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3d9ea5cb")
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-col-12 p-mb-2" }
const _hoisted_3 = { class: "p-field p-fluid" }
const _hoisted_4 = { class: "p-d-flex p-jc-end" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_Center = _resolveComponent("Center")!
  const _component_CommentComponent = _resolveComponent("CommentComponent")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Card, { class: "comment-card" }, {
        header: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ]),
        content: _withCtx(() => [
          _createVNode(_component_ScrollPanel, {
            style: {"width":"100%","height":"200px"},
            id: "hola"
          }, {
            default: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_Center, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_BrainSpinner)
                    ]),
                    _: 1
                  }))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.comments, (comment) => {
                    return (_openBlock(), _createBlock(_component_CommentComponent, {
                      comment: comment,
                      key: comment._id || comment.date || comment.message,
                      onOnEdit: _ctx.onEdit,
                      onOnCopy: _cache[0] || (_cache[0] = 
                () =>
                  _ctx.toast.showSuccessfullyDone(
                    'Comentario copiado y enviado al portapapeles'
                  )
              )
                    }, null, 8, ["comment", "onOnEdit"]))
                  }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("form", {
      class: "p-col-12 p-mt-2",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.save()), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Textarea, {
          placeholder: "Dejar comentario...",
          autoResize: true,
          rows: "4",
          modelValue: _ctx.textAreaValue,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.textAreaValue = $event)),
          ref: "textAreaRef"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.editing)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              disabled: _ctx.textAreaValue.length <= 0 || _ctx.saving,
              onClick: _ctx.onCancelEdit,
              type: "button",
              class: " p-button-secondary p-button-rounded p-mr-2",
              label: "Cancelar Edición"
            }, null, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          loading: _ctx.saving,
          disabled: _ctx.textAreaValue.length <= 0 || _ctx.saving,
          type: "submit",
          class: " p-button-primary p-button-rounded",
          label: _ctx.editing ? 'Guardar Edición' : 'Agregar'
        }, null, 8, ["loading", "disabled", "label"])
      ])
    ], 32)
  ]))
}