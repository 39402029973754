import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-52071552")
const _hoisted_1 = { class: "comment-container" }
const _hoisted_2 = { class: "p-grid p-nogutter p-jc-between" }
const _hoisted_3 = { class: "p-col-10 p-xl-11" }
const _hoisted_4 = { class: "comment-text" }
const _hoisted_5 = { class: "p-col-auto p-pt-2" }
const _hoisted_6 = { class: "p-col-12" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          _createElementVNode("b", null, _toDisplayString(_ctx.date) + " " + _toDisplayString(_ctx.comment.author?.name), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.comment.message), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Button, {
          class: "p-button-text overlay-menu-trigger",
          icon: "pi pi-ellipsis-v",
          onClick: _ctx.toggle
        }, null, 8, ["onClick"]),
        _createVNode(_component_Menu, {
          id: "overlay_menu",
          ref: "menu",
          model: _ctx.items,
          popup: true
        }, null, 8, ["model"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_Divider)
      ])
    ])
  ]))
}