

import { computed, ComputedRef, defineComponent, onMounted, PropType, ref } from 'vue'
import { DateTime } from 'luxon'

import { CSSColors } from '@/models/enums'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { PersonRoles } from '@/models/person/enums'
import { truncate } from '@/utils/math'
import { isLastItem } from '@/utils/arrays'
import { CommentTarget, CourseStatus, LinkType, CourseHours } from '@/models/course/enums'
import { Comment } from '@/models/comments'
import { CommentAPI } from '@/api/comments.api'
import { Course } from '@/models/course/classes/Course'
import ToolLinks from '@/components/courses/boxes/ToolLinks.vue'
import GeneralPerformanceCard from '@/components/courses/cards/GeneralPerformanceCard.vue'
import DoughnutChartCard from '@/components/courses/cards/DoughnutChartCard.vue'
import CourseCommentsBox from '@/components/commons/boxes/CommentBox/CommentBox.vue'
import PerformanceGeneralInfoCard from '@/components/courses/cards/PerformanceGeneralInfoCard.vue'
import PerformanceStaffInfo from '@/components/courses/cards/PerformanceStaffInfo.vue'
import { useToast } from 'primevue/usetoast'
import { EvaluateSummary } from '@/models/course-feedbacks/enums'
import { AverageSummary } from '@/models/course-feedbacks/summaries/classes/AverageSummary'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { Syllabus } from '@/models/academic-definitions/interfaces/composition/Syllabus'
import { Student } from '@/models/course/builder/student.builder'
import { FeedbackSummary } from '@/models/course-feedbacks/summaries/classes/FeedbackSummary'
import { StaffMember } from '@/models/course/builder/staffMember.builder'
import { useCommentBoxEventsHandler } from '@/hooks/useCommentBoxEventsHandler'

export default defineComponent({
  components: {
    ToolLinks,
    CourseCommentsBox,
    DoughnutChartCard,
    GeneralPerformanceCard,
    PerformanceGeneralInfoCard,
    PerformanceStaffInfo
  },
  props: {
    course: {
      type: Object as PropType<Course>,
      required: true
    },
    syllabus: {
      type: Object as PropType<Syllabus>,
      required: true
    },
    students: {
      type: Array as PropType<Student[]>,
      required: true
    },
    summaries: {
      type: Array as PropType<ClassFeedbackSummary[]>,
      required: true
    },
    staff: {
      type: Array as PropType<StaffMember[]>,
      required: true
    },
    staffIsLoading: {
      type: Boolean,
      required: true
    }
  },
  emits: ['loadStaff'],
  setup (props) {
    const toast = useToast()
    const summaryRole = ref(EvaluateSummary)
    const course = computed(() => props.course) as ComputedRef<Course>
    const courseStatus = computed(() => course.value.status)
    const summaries = computed<ClassFeedbackSummary[]>(() => props.summaries as ClassFeedbackSummary[])
    const avgRate = computed<AverageSummary>(() => ClassFeedbackSummary.getAverage(summaries?.value || []))
    const summary = computed<ClassFeedbackSummary>(() => (summaries.value)[(summaries.value).length - 1])
    const comments = ref<Comment[]>([])
    const loadingComments = ref(true)
    const staff = computed<StaffMember[]>(() => props.staff)
    const { handleSave: handleSaveComment } = useCommentBoxEventsHandler(comments)
    onMounted(async () => {
      try {
        comments.value = await CommentAPI.findAllComments(CommentTarget.Course, course.value._id)
      } catch (error) {
        toast.add({
          summary: ToastSummaries.Error,
          detail: 'Ocurrió un error al cargar los comentarios',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
        console.error(error)
      } finally {
        loadingComments.value = false
      }
    })

    function sortByUnenroll (x: StaffMember, y: StaffMember) {
      return (x.status?.unenroll === y.status?.unenroll ? 0 : !x.status?.unenroll ? -1 : 1)
    }
    const tutors = computed((): StaffMember[] => staff.value.filter(teacher => teacher.user?.role === PersonRoles.Tutor).sort(sortByUnenroll))
    const teachers = computed((): StaffMember[] => staff.value.filter(teacher => teacher.user?.role === PersonRoles.Teacher).sort(sortByUnenroll))
    const teachersSummary = computed((): FeedbackSummary[] => summary.value?.getStaffSummariesByRole(PersonRoles.Teacher))
    const tutorssSummary = computed((): FeedbackSummary[] => summary.value?.getStaffSummariesByRole(PersonRoles.Tutor))
    const summariesSorted = summaries.value?.filter((summary) => summary.npsSummary === null || summary.npsSummary === undefined).sort((prev, value) => prev.classNumber - value.classNumber)
    const lastContentRating = summariesSorted.slice(-1)[0]?.contentSummary?.rating?.value
    const lastContentVariation = summariesSorted.slice(-1)[0]?.contentSummary?.rating?.variation
    return {
      courseStatus,
      CourseStatus,
      ClassFeedbackSummary,
      PersonRoles,
      avgRate,
      summary,
      summaryRole,
      tutors,
      teachers,
      teachersSummary,
      tutorssSummary,
      comments,
      loadingComments,
      CSSColors,
      truncate,
      LinkType,
      CourseHours,
      isLastItem,
      slackLink: computed(() => course.value.toolsLinks.find(linkObj => linkObj.type === LinkType.Slack)?.value),
      sharedFolderLink: computed(() => course.value.toolsLinks.find(linkObj => linkObj.type === LinkType.SharedFolder)?.value),
      teacherFolderLink: computed(() => course.value.toolsLinks.find(linkObj => linkObj.type === LinkType.TeachersSharedFolder)?.value),
      courseProgressData: computed(() => [{ value: course.value.classesTaught, label: 'Progreso' }, { value: (course.value.classes.length - course.value.classesTaught), label: 'Restante' }]),
      DateTime,
      handleSaveComment,
      lastContentRating,
      lastContentVariation
    }
  }
})
