import { CommentAPI } from '@/api/comments.api'
import { Comment, CommentBoxSaveEvent } from '@/models/comments'
import { Ref } from 'vue'
import { useToastService } from './useToastService'

interface UseCommentBoxEventsHandler {
  handleSave (e: CommentBoxSaveEvent): Promise<void>
}

export const useCommentBoxEventsHandler = (comments: Ref<Comment[]>): UseCommentBoxEventsHandler => {
  const toast = useToastService()
  return {
    async handleSave (e: CommentBoxSaveEvent) {
      try {
        e.setSaving(true)
        if (!e.id) {
          const created = await CommentAPI.create(e.value)
          comments.value = [...comments.value, created]
        } else {
          const updated = await CommentAPI.update(e.value, e.id)
          comments.value = comments.value.map((c) => c._id === e.id ? updated : c)
        }
      } catch (error) {
        console.log(error)
      } finally {
        toast.showSuccessfullyDone()
        e.setSaving(false)
      }
    }
  }
}
