
import {
  ComponentPublicInstance,
  computed,
  defineComponent,
  PropType,
  ref
} from 'vue'
import { DateTime } from 'luxon'
import Divider from 'primevue/divider'
import Menu from 'primevue/menu'
import { FormatDates } from '@/models/enums'
import useClipboard from 'vue-clipboard3'
import { Comment } from '@/models/comments'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { MenuItem } from 'primevue/menuitem'

export default defineComponent({
  components: {
    Divider,
    Menu
  },
  props: {
    comment: {
      required: true,
      type: Object as PropType<Comment>
    }
  },
  emits: ['onCopy', 'onEdit'],
  setup (props, { emit }) {
    const session = useUserSessionStore()
    const { toClipboard } = useClipboard()
    const menu = ref<ComponentPublicInstance<Menu> | null>(null)
    const items = ref<MenuItem[]>([
      {
        label: 'Opciones',
        items: [
          {
            label: 'Editar',
            icon: 'pi pi-pencil',
            disabled:
              !(typeof props.comment._id !== 'undefined') ||
              props.comment.author.personId !== session.currentUser._id,
            command: () => {
              emit('onEdit', props.comment)
            }
          },
          {
            label: 'Copiar',
            icon: 'pi pi-copy',
            command: () => {
              toClipboard(props.comment.message).then(() => {
                emit('onCopy')
              })
            }
          }
        ]
      }
    ])

    return {
      items,
      menu,
      toggle: (event: MouseEvent) => {
        menu.value && menu.value.toggle(event)
      },
      date: computed(() =>
        props.comment.updatedAt
          ? DateTime.fromJSDate(props.comment.updatedAt).toFormat(
            FormatDates.LatinAmericaShort
          )
          : undefined
      )
    }
  }
})
