
import { ComponentPublicInstance, defineComponent, ref, toRefs } from 'vue'
import Textarea from 'primevue/textarea'
import ScrollPanel from 'primevue/scrollpanel'
import CommentComponent from '@/components/commons/boxes/CommentBox/Comment.vue'
import Center from '@/components/commons/boxes/Center.vue'
import { useUserSessionStore } from '@/store/modules/commons/user-session-store'
import { Comment, CommentAuthor, CommentBoxSaveEvent } from '@/models/comments'
import BrainSpinner from '@/components/commons/BrainSpinner.vue'
import { useToastService } from '@/hooks/useToastService'
export default defineComponent({
  components: {
    Textarea,
    CommentComponent,
    ScrollPanel,
    BrainSpinner,
    Center
  },
  props: {
    title: {
      type: String,
      default: 'Comentarios'
    },
    comments: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    personId: {
      type: String,
      required: false
    },
    courseId: {
      type: String,
      required: false
    }
  },
  emits: ['onSave'],
  setup (props, { emit }) {
    const { personId, courseId } = toRefs(props)
    const toast = useToastService()
    const session = useUserSessionStore()
    const editing = ref<Comment | null>(null)
    const textAreaRef = ref<ComponentPublicInstance<
      HTMLTextAreaElement
    > | null>(null)
    const textAreaValue = ref<string>('')
    const saving = ref<boolean>(false)
    const setSaving = (val: boolean) => (saving.value = val)
    return {
      textAreaValue,
      textAreaRef,
      editing,
      saving,
      toast,
      save () {
        const data = new Comment(
          textAreaValue.value.trim(),
          new CommentAuthor(
            session.currentUser._id,
            session.currentUser.name,
            session.currentUser.avatar
          ),
          new Date(),
          undefined,
          personId.value,
          courseId.value
        )
        if (!editing.value) {
          emit('onSave', new CommentBoxSaveEvent(data, setSaving))
        } else {
          emit('onSave', new CommentBoxSaveEvent(data, setSaving, editing.value._id))
        }
        textAreaValue.value = ''
      },
      onEdit (comment: Comment) {
        if (textAreaRef.value) {
          editing.value = comment
          textAreaRef.value.$el.select()
          textAreaValue.value = comment.message
          toast.showInfo('Está editando un comentario')
        }
      },
      onCancelEdit () {
        editing.value = null
        toast.showInfo('Ha dejado de editar el comentario')
      }
    }
  }
})
