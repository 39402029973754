
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { useToast } from 'primevue/usetoast'
import { defineComponent } from 'vue'
import useClipboard from 'vue-clipboard3'

export default defineComponent({
  props: {
    value: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onCopy', 'onOpenLink'],
  setup (_, ctx) {
    const { toClipboard } = useClipboard()
    const toast = useToast()
    return {
      openUrl: (url: string) => {
        window.open(url, '_blank')
        ctx.emit('onOpenLink')
      },
      copy: async (txt: string) => {
        try {
          await toClipboard(txt)
          toast.add({
            summary: ToastSummaries.Copied,
            detail: 'Copiado al portapapeles.',
            severity: ToastSeverities.Info,
            life: ToastLife.Default
          })
        } catch (error) {
          toast.add({
            summary: ToastSummaries.Error,
            detail: 'Error al copiar.',
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        } finally {
          ctx.emit('onCopy')
        }
      }
    }
  }
})
