
import { defineComponent } from 'vue'
import InputLink from '@/components/commons/inputs/InputLink.vue'

export default defineComponent({
  props: {
    slack: {
      type: String
    },
    teacherFolder: {
      type: String
    },
    sharedFolder: {
      type: String
    }
  },
  components: {
    InputLink
  }
})
