
import Divider from 'primevue/divider'
import TeacherInfoCard from '@/components/courses/cards/TeacherInfoCard.vue'
import TutorInfoCard from '@/components/courses/cards/TutorInfoCard.vue'
import { PropType } from 'vue'
import { FeedbackSummary } from '@/models/course-feedbacks/summaries/classes/FeedbackSummary'
import { truncate } from '@/utils/math'
import { IStaffNameAndAverageRating } from '@/models/course-feedbacks/summaries/interfaces/IStaffNameAndAverageRating'
export default {
  props: {
    value: { required: true, type: Number },
    title: { required: true, type: String },
    teacher: { required: true, default: false, type: Boolean },
    summary: {
      type: Array as PropType<Array<FeedbackSummary>>,
      required: false
    },
    isFinishied: {
      type: Boolean,
      default: false
    },
    teachersWithRating: {
      type: Array as PropType<Array<IStaffNameAndAverageRating>>,
      required: true
    },
    tutorsWithRating: {
      type: Array as PropType<Array<IStaffNameAndAverageRating>>,
      required: true
    }
  },
  components: {
    Divider,
    TeacherInfoCard,
    TutorInfoCard
  },
  setup (): Record<string, (value: number, digit?: number) => number> {
    return { truncate }
  }
}
