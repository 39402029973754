export class CommentAuthor {
  constructor (
    readonly personId: string,
    readonly name: string,
    readonly avatar?: string
  ) {}
}

export interface CommentDto {
  message: string
  author: CommentAuthor
  _id?: string
  personId?: string
  courseId?: string
  date?: string
  updatedAt?: string
  createdAt?: string
}

// export class Comment {
//   message: string
//   author: CommentAuthor
//   _id?: string
//   personId?: string | null
//   courseId?: string | null
//   date?: string
//   constructor (
//     message: string,
//     author: CommentAuthor,
//     _id?: string,
//     personId?: string,
//     courseId?: string,
//     date?: string
//   ) {
//     this.message = message
//     this.author = author
//     this.personId = personId
//     this.courseId = courseId
//     this.date = date
//     this._id = _id
//   }
// }

export class Comment {
  constructor (
    readonly message: string,
    readonly author: CommentAuthor,
    readonly date?: Date,
    readonly _id?: string,
    readonly personId?: string,
    readonly courseId?: string,
    readonly createdAt?: Date,
    readonly updatedAt?: Date
  ) {}
}

export class CommentBoxSaveEvent {
  constructor (
    readonly value: Comment,
    readonly setSaving: (value: boolean) => void,
    readonly id?: string
  ) {}
}
