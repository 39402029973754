import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5c901f16")
const _hoisted_1 = { class: "p-p-2" }
const _hoisted_2 = {
  key: 1,
  class: "p-grid position-relative"
}
const _hoisted_3 = {
  key: 0,
  class: "p-col-6 p-d-flex p-ai-center p-jc-center p-flex-column"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "p-col-6 p-d-flex p-ai-center p-jc-center p-flex-column"
}
const _hoisted_6 = { class: "p-text-center label-type-progress" }
const _hoisted_7 = { "data-testid": "percentage" }
const _hoisted_8 = {
  key: 2,
  class: "p-text-center",
  "data-testid": "labelTypeError"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BrainSpinner = _resolveComponent("BrainSpinner")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, null, _createSlots({
    content: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_BrainSpinner, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              "data-testid": "chartContainer",
              class: _normalizeClass(_ctx.showLabels ? 'p-col-6' : 'p-col-12')
            }, [
              _createVNode(_component_Chart, {
                options: _ctx.chartOptions,
                type: "doughnut",
                data: _ctx.chartDataConfig
              }, null, 8, ["options", "data"])
            ], 2),
            _renderSlot(_ctx.$slots, "labels", {
              labels: _ctx.dataLabels,
              values: _ctx.dataValues
            }, () => [
              (_ctx.showLabels)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_ctx.labelType === _ctx.DoughnutChartLabelType.Table)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("table", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartData, ({label, value}) => {
                              return (_openBlock(), _createElementBlock("tr", { key: label }, [
                                _createElementVNode("th", null, _toDisplayString(value) + "%", 1),
                                _createElementVNode("td", null, _toDisplayString(label), 1)
                              ]))
                            }), 128))
                          ])
                        ]))
                      : (_ctx.labelType === _ctx.DoughnutChartLabelType.Progress)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", null, [
                                _createElementVNode("b", _hoisted_7, _toDisplayString(_ctx.percentage(_ctx.dataValues[0], _ctx.dataValues[0] + _ctx.dataValues[1], true)) + "%", 1)
                              ]),
                              _createElementVNode("span", null, _toDisplayString(_ctx.dataValues[0]) + " de " + _toDisplayString(_ctx.dataValues[0] + _ctx.dataValues[1]), 1)
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_8, "Error: Invalid labelType prop"))
                  ]))
                : _createCommentVNode("", true)
            ], true)
          ]))
    ]),
    _: 2
  }, [
    (_ctx.title)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h5", {
                style: _normalizeStyle({ 'text-align': _ctx.titleAlign })
              }, _toDisplayString(_ctx.title), 5)
            ])
          ])
        }
      : undefined
  ]), 1024))
}