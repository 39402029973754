
import { defineComponent, PropType } from 'vue'
import { FeedbackSummary } from '@/models/course-feedbacks/summaries/classes/FeedbackSummary'
import { truncate } from '@/utils/math'
import { IStaffNameAndAverageRating } from '@/models/course-feedbacks/summaries/interfaces/IStaffNameAndAverageRating'
import { RouteLocationRaw } from 'vue-router'
import { EducationRouteNames } from '@/router/route-names'
export default defineComponent({
  props: {
    teachers: {
      type: Array as PropType<Array<FeedbackSummary>>,
      required: false
    },
    teachersFinishied: {
      type: Array as PropType<Array<IStaffNameAndAverageRating>>,
      required: false
    },
    isFinishied: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  setup () {
    return {
      truncate,
      toPersonProfile: (personId: string): RouteLocationRaw => {
        console.log(personId)
        return ({
          name: EducationRouteNames.PERSON_PROFILE_GENERAL,
          params: { personId }
        })
      }
    }
  }
})
