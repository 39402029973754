import { Comment, CommentDto } from '@/models/comments'
import { CommentTarget } from '@/models/course/enums'
import axios from 'axios'
import { CRUD } from './crud'

class CommentApi implements CRUD<Comment> {
  private readonly baseURL: string

  constructor() {
    this.baseURL = process.env.ACADEMIC_API_ENDPOINT
  }

  private convertToComment(iComment: CommentDto): Comment {
    return new Comment(
      iComment.message,
      iComment.author,
      iComment.date ? new Date(iComment.date) : undefined,
      iComment._id,
      iComment.personId,
      iComment.courseId,
      iComment.createdAt ? new Date(iComment.createdAt) : undefined,
      iComment.updatedAt ? new Date(iComment.updatedAt) : undefined
    )
  }

  public async create(dto: Partial<Comment>): Promise<Comment> {
    const { data: commentCreated } = await axios.post<CommentDto>(
      `${this.baseURL}/v1/comments`,
      dto
    )
    return this.convertToComment(commentCreated)
  }

  public async update(dto: Partial<Comment>, id: string): Promise<Comment> {
    const { data: commentUpdated } = await axios.patch<CommentDto>(
      `${this.baseURL}/v1/comments/${id}`,
      dto
    )
    return this.convertToComment(commentUpdated)
  }

  public async findAllComments(
    target: CommentTarget,
    targetId: string
  ): Promise<Comment[]> {
    const { data } = await axios.get<CommentDto[]>(
      `${this.baseURL}/v1/comments/${target}/${targetId}`
    )
    return data.map((comment) => this.convertToComment(comment))
  }
}

export const CommentAPI = new CommentApi()
