import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a8d462fc")
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-sm-12 p-md-12 p-lg-3" }
const _hoisted_3 = { class: "p-grid p-ai-end" }
const _hoisted_4 = { class: "p-col-12" }
const _hoisted_5 = { class: "custom-chart-labels-container p-text-center" }
const _hoisted_6 = { class: "percentage-completed" }
const _hoisted_7 = { class: "percentage" }
const _hoisted_8 = { class: "classes-of" }
const _hoisted_9 = { class: "p-col-12" }
const _hoisted_10 = { class: "p-sm-12 p-md-12 p-lg-9" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DoughnutChartCard = _resolveComponent("DoughnutChartCard")!
  const _component_PerformanceGeneralInfoCard = _resolveComponent("PerformanceGeneralInfoCard")!
  const _component_PerformanceStaffInfo = _resolveComponent("PerformanceStaffInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_DoughnutChartCard, {
            class: "course-progress-chart-card",
            title: "Egresados",
            titleAlign: "center",
            chartData: _ctx.graduatesChartData,
            loading: _ctx.loadingGraduates,
            maintainAspectRatio: false
          }, {
            labels: _withCtx(({ values }) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.truncate((values[0] / (values[0] + values[1])) * 100, 0)) + "%", 1)
                ]),
                _createElementVNode("span", _hoisted_8, _toDisplayString(values[0]) + "/" + _toDisplayString(values[0] + values[1]), 1)
              ])
            ]),
            _: 1
          }, 8, ["chartData", "loading"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_PerformanceGeneralInfoCard, {
            value: _ctx.value,
            variation: _ctx.variation,
            lastValue: _ctx.lastValue
          }, null, 8, ["value", "variation", "lastValue"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_PerformanceStaffInfo, {
        isFinishied: true,
        teachersValue: _ctx.teachersValue,
        tutorsValue: _ctx.tutorsValue,
        tutorsSummary: _ctx.tutorssSummary,
        teachersSummary: _ctx.teachersSummary,
        tutorsWithRating: _ctx.tutorsWithRating,
        teachersWithRating: _ctx.teachersWithRating
      }, null, 8, ["teachersValue", "tutorsValue", "tutorsSummary", "teachersSummary", "tutorsWithRating", "teachersWithRating"])
    ])
  ]))
}