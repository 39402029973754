
import { computed, ComputedRef, defineComponent, PropType } from 'vue'
import Chart from 'primevue/chart'
import { CSSColors, DoughnutChartLabelType, TitleAlign } from '@/models/enums'
import { percentage } from '@/utils/math'
import { ChartDataItem } from '@/models/components/charts/ChartData'
import { ChartOptions } from 'chart.js'

export default defineComponent({
  components: {
    Chart
  },
  props: {
    title: {
      type: String
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    titleAlign: {
      type: String as PropType<TitleAlign>,
      default: TitleAlign.Start
    },
    labelType: {
      type: String as PropType<DoughnutChartLabelType>,
      default: DoughnutChartLabelType.Table
    },
    chartData: {
      type: Array as PropType<Array<ChartDataItem>>,
      required: true
    },
    maintainAspectRatio: {
      type: Boolean,
      default: true
    },
    chartColors: {
      type: Array as PropType<CSSColors[]>,
      default: () => [
        CSSColors.PurpleBrain,
        CSSColors.PrimaryColor,
        CSSColors.GreenBrain,
        CSSColors.RedBrain,
        CSSColors.LightblueBrain
      ]
    },
    loading: {
      type: Boolean
    }
  },
  setup (props) {
    const chartData = computed(() => props.chartData)
    if (props.labelType === DoughnutChartLabelType.Progress && props.chartData.length > 2) {
      console.warn(`WARNING: If labelsType is ${DoughnutChartLabelType.Progress} the chartData length should not be greater than 2.`)
    }

    const dataLabels: ComputedRef<string[]> = computed(() => chartData.value.map(dataItem => dataItem.label))
    const dataValues: ComputedRef<number[]> = computed(() => chartData.value.map(dataItem => dataItem.value))

    const chartOptions:ChartOptions = {
      plugins: {
        legend: {
          display: false
        }
      },
      responsive: true,
      maintainAspectRatio: props.maintainAspectRatio
    }
    const chartDataConfig = computed(() => {
      return {
        labels: dataLabels.value,
        datasets: [
          {
            label: props.title,
            data: dataValues.value,
            backgroundColor: props.chartColors,
            hoverBackgroundColor: props.chartColors
          }
        ]
      }
    })

    return {
      chartOptions,
      chartDataConfig,
      percentage,
      dataLabels,
      dataValues,
      DoughnutChartLabelType
    }
  }
})
