import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      "data-testid": "copyButton",
      disabled: !_ctx.value,
      class: "p-button-secondary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copy(_ctx.value))),
      icon: "pi pi-copy"
    }, null, 8, ["disabled"]),
    _createVNode(_component_InputText, {
      readonly: _ctx.readonly,
      id: "slack",
      type: "text",
      value: _ctx.value
    }, null, 8, ["readonly", "value"])
  ]))
}