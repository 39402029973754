
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { truncate } from '@/utils/math'
import { CSSColors } from '@/models/enums'
import { RouteNames } from '@/router/route-names'
import { IStaffNameAndAverageRating } from '@/models/course-feedbacks/summaries/interfaces/IStaffNameAndAverageRating'
import PerformanceGeneralInfoCard from '@/components/courses/cards/PerformanceGeneralInfoCard.vue'
import PerformanceStaffInfo from '@/components/courses/cards/PerformanceStaffInfo.vue'
import DoughnutChartCard from '@/components/courses/cards/DoughnutChartCard.vue'
import { ChartDataItem } from '@/models/components/charts/ChartData'
import { getCertificatesByCourseId } from '@/api/course.api'
import { Course } from '@/models/course/classes/Course'
import { Student } from '@/models/course/builder/student.builder'
export default defineComponent({
  components: {
    PerformanceGeneralInfoCard,
    PerformanceStaffInfo,
    DoughnutChartCard
  },
  props: {
    tutorsValue: { required: true, type: Number },
    teachersValue: { required: true, type: Number },
    value: { required: true, type: Number },
    lastValue: { required: true, type: Number },
    variation: { required: true, type: Number },
    contentRating: {
      type: Number,
      required: true
    },
    teacherRating: {
      type: Number,
      required: true
    },
    tutorRating: {
      type: Number,
      required: true
    },
    teachersWithRating: {
      type: Array as PropType<Array<IStaffNameAndAverageRating>>,
      required: true
    },
    tutorsWithRating: {
      type: Array as PropType<Array<IStaffNameAndAverageRating>>,
      required: true
    },
    courseProgressData: {
      type: Array as PropType<Array<ChartDataItem>>,
      required: true
    },
    course: {
      type: Course,
      required: true
    },
    students: {
      type: Array as PropType<Student[]>,
      required: true
    }
  },
  setup (props) {
    const graduatesChartData = ref<ChartDataItem[]>([])
    const loadingGraduates = ref<boolean>(true)
    const numberOfActivesStudents = computed(() => props.students.filter(s => s?.user?.active).length)
    const course = computed(() => props.course)
    onMounted(async () => {
      try {
        loadingGraduates.value = true
        const certificates = await getCertificatesByCourseId((course.value?._id as string))
        graduatesChartData.value = [
          {
            label: 'Egresados',
            value: certificates.length
          },
          {
            label: 'No egresados',
            value: numberOfActivesStudents.value - certificates.length
          }
        ]
      } catch (error) {
        console.error(error)
      } finally {
        loadingGraduates.value = false
      }
    })
    return {
      RouteNames,
      truncate,
      CSSColors,
      graduatesChartData,
      loadingGraduates
    }
  }
})
