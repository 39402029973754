
import Card from 'primevue/card'
import Divider from 'primevue/divider'
import PerformanceDetails from '@/components/courses/cards/PerformanceDetails.vue'
import { PropType } from 'vue'
import { FeedbackSummary } from '@/models/course-feedbacks/summaries/classes/FeedbackSummary'
import { IStaffNameAndAverageRating } from '@/models/course-feedbacks/summaries/interfaces/IStaffNameAndAverageRating'
export default {
  props: {
    tutorsValue: { required: true, type: Number },
    teachersValue: { required: true, type: Number },
    tutorsSummary: {
      type: Array as PropType<Array<FeedbackSummary>>,
      required: false
    },
    teachersSummary: {
      type: Array as PropType<Array<FeedbackSummary>>,
      required: false
    },
    isFinishied: {
      type: Boolean,
      default: false
    },
    teachersWithRating: {
      type: Array as PropType<Array<IStaffNameAndAverageRating>>,
      required: true
    },
    tutorsWithRating: {
      type: Array as PropType<Array<IStaffNameAndAverageRating>>,
      required: true
    }
  },
  components: {
    Card,
    Divider,
    PerformanceDetails
  }
}
